<template>
     <div>
          <el-dialog v-model="dialogVisible" :lock-scroll="false" width="1219px" :close-on-click-modal="false" top="10vh">

               <vali-input-group ref="valiGroup" :validateForm="validate">
                    <div class="mod13 flex-col">
                         <div class="outer1 flex-col">
                              <img class="label1" src="@/assets/inquiry/inq_icon11.png" @click="dialogVisible = false" />
                              <span class="info1">{{ quoDTO.quoteId ? L['编辑报价'] : L['发起报价'] }}</span>
                              <div class="block1 flex-row justify-between">
                                   <div class="bd1 flex-col"></div>
                                   <span class="word1">{{ L['报价信息'] }}</span>
                              </div>

                              <!-- 产品报价start -->
                              <div>
                                   <span class="word2">{{ L['产品报价'] }}</span>
                                   <div class="block2 flex-col">
                                        <div class="outer9 flex-col">
                                             <div class="outer10 flex-row">
                                                  <span class="word33">{{ L['产品图片'] }}</span>
                                                  <div class="bd4">
                                                       <span class="word34">*</span> <span class="word35">{{ L['产品名称']
                                                       }}</span>
                                                  </div>
                                                  <span class="word36">{{ L['规格/型号'] }}</span>
                                                  <div class="bd5">
                                                       <span class="word37">*</span> <span class="word38">{{ L['数量']
                                                       }}</span>
                                                  </div>
                                                  <div class="bd6">
                                                       <span class="txt17">*</span> <span class="word39">{{ L['单价(元)']
                                                       }}</span>
                                                  </div>
                                                  <div class="bd7 flex-col">
                                                       <input type="checkbox" @change="changeRateType"
                                                            :checked="totalTaxType == 1">
                                                  </div>
                                                  <span class="word40">{{ L['含税'] }}</span>
                                                  <div class="bd8">
                                                       <span class="txt18">*</span> <span class="info18">{{ L['税率'] }}</span>
                                                  </div>
                                                  <span class="word41">{{ L['小计(元)'] }}</span>
                                             </div>
                                        </div>
                                   </div>
                                   <!-- 产品列表start -->
                                   <div class="block4 flex_row_center_center" v-for="(pro, proIdx) in quoDTO.productList"
                                        :key="pro.key">
                                        <div class="outer2 flex-row">
                                             <div class="up1">
                                                  <div class="flex-row" v-if="!pro.productImageUrl">
                                                       <label :for="pro.key">
                                                            <div class="box1 flex-col">
                                                                 <div class="outer3 flex-col"></div>
                                                                 <div class="outer4 flex-col"></div>
                                                            </div>
                                                       </label>
                                                       <input type="file" :id="pro.key" accept="image/*" :ref="pro.key"
                                                            @change="changeFile($event, pro, pro.key)">
                                                  </div>
                                                  <div v-else>
                                                       <span class="el-icon-close" @click="clearImage(pro, pro.key)"></span>
                                                       <el-image :src="pro.productImageUrl"
                                                            :preview-src-list="[pro.productImageUrl]"></el-image>
                                                  </div>
                                             </div>
                                             <div class="container_quo1">
                                                  <div class="box23 flex-col">
                                                       <val-input type="text" v-model="pro.productName" maxlength="30"
                                                            :definekey="pro.key" label="productName"></val-input>
                                                  </div>
                                             </div>
                                             <div class="box3 flex-col">
                                                  <val-input type="text" v-model="pro.spec" maxlength="50"
                                                       :definekey="pro.key"></val-input>
                                             </div>
                                             <div class="container_num">
                                                  <div class="box4 flex_row_between_center">
                                                       <val-input type="number" v-model="pro.buyNum" max="99999" min="1"
                                                            :definekey="pro.key" label="buyNum" mode="int"
                                                            class="vali_input_buyNum"></val-input>
                                                       <div class="block5 flex_row_around_center">
                                                            <el-autocomplete v-model="pro.unitName"
                                                                 :fetch-suggestions="querySearch"
                                                                 popper-class="my-autocomplete" placeholder="请选择"
                                                                 @select="handleSelect($event, pro)" maxlength="5">
                                                                 <template #suffix>
                                                                      <el-icon class="el-input__icon">
                                                                           <span class="el-icon-edit"></span>
                                                                      </el-icon>
                                                                 </template>
                                                                 <template #default="{ item }">
                                                                      <div style="width: 50px;text-align: left;">{{ item }}
                                                                      </div>
                                                                 </template>
                                                            </el-autocomplete>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div class="container_price">
                                                  <div class="box5 flex-col">
                                                       <val-input type="number" v-model="pro.price" max="9999999"
                                                            :definekey="pro.key" label="price" mode="float"></val-input>
                                                  </div>
                                             </div>
                                             <div class="container_tax">
                                                  <div class="box6 flex-col">
                                                       <val-input type="number" v-model="pro.taxRate" mode="int"
                                                            :definekey="pro.key" label="taxRate" max="50"></val-input>
                                                  </div>
                                             </div>

                                             <span class="info5">%</span>
                                             <span class="word5">{{ calToTaxed(pro.buyNum, pro.price, pro.taxRate) }}</span>

                                             <div class="box7 flex-col" v-if="proIdx > 0" @click="removeProPanel(pro.key)">
                                                  <div class="main1 flex-col"></div>
                                             </div>
                                        </div>
                                   </div>
                                   <!-- 产品列表end -->

                                   <div class="block6 flex-col" @click="addProductPanel"
                                        v-if="quoDTO.productList.length < 30">
                                        <span class="txt4">+&nbsp;{{ L['添加产品'] }}</span>
                                   </div>
                              </div>
                              <div>
                                   <!-- 其他费用start -->
                                   <span class="info6">{{ L['其他费用'] }}</span>
                                   <div class="block7 flex-col">
                                        <div class="bd3 flex-col">
                                             <div class="group2 flex-row">
                                                  <div class="mod10">
                                                       <span class="info7">*</span> <span class="txt55">{{ L['收费项'] }}</span>
                                                  </div>
                                                  <div class="mod11">
                                                       <span class="info8">*</span>
                                                       <span class="word66">{{ L['报价（元）'] }}</span>
                                                       <span class="word77">{{ totalTaxType == 1 ? L['含税'] : L['未含税']
                                                       }}</span>
                                                  </div>
                                                  <div class="mod12">
                                                       <span class="word8">*</span>
                                                       <span class="txt6">&nbsp;{{ L['税率'] }}</span>
                                                  </div>
                                                  <span class="word9">{{ L['小计(元)'] }}</span>
                                             </div>
                                        </div>

                                        <!-- 其他费用 -->
                                        <div class="layer26 flex_row_center_center" v-for="(fee, feeIdx) in quoDTO.feeList"
                                             :key="fee.key">
                                             <div class="box6 flex-row">
                                                  <div class="container_feeName">
                                                       <div class="bd9 flex-col">
                                                            <val-input type="text" v-model="fee.feeName" :definekey="fee.key"
                                                                 label="feeName" maxlength="30"></val-input>
                                                       </div>
                                                  </div>

                                                  <div class="container_fee">
                                                       <div class="bd10 flex-col">
                                                            <val-input type="number" v-model="fee.fee" :definekey="fee.key"
                                                                 label="fee" max="9999999" mode="float"></val-input>
                                                       </div>
                                                  </div>

                                                  <div class="container_feeTaxRate">
                                                       <div class="bd11 flex-col">
                                                            <val-input type="number" v-model="fee.taxRate"
                                                                 :definekey="fee.key" label="feeTaxRate" max="50"
                                                                 mode="int"></val-input>
                                                       </div>
                                                  </div>

                                                  <span class="info24">%</span>
                                                  <span class="info25">{{ calToTaxed(1, fee.fee, fee.taxRate) }}</span>
                                                  <div class="bd12 flex-col" @click="removeFeePanel(feeIdx)">
                                                       <div class="bd13 flex-col"></div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div class="block8 flex-row justify-between" v-if="quoDTO.feeList.length < 10">
                                        <div class="section4 flex-col" @click="addfeePanel">
                                             <span class="word10">+&nbsp;{{ L['添加收费项'] }}</span>
                                        </div>
                                        <div class="section5 flex-col"></div>
                                   </div>
                                   <!-- 其他费用end -->

                              </div>

                              <div class="middle1 flex_column_start_end">
                                   <div class="block9">
                                        <span class="info9">{{ L['未税价：'] }}</span> <span class="word11">{{ L['¥'] }}</span>
                                        <span class="word12">{{ getPartNumber(calcateAmountUnTaxed, 'int') }}</span> <span
                                             class="txt71">{{ getPartNumber(calcateAmountUnTaxed, 'decimal') }}</span>
                                   </div>
                                   <div class="block10">
                                        <span class="txt8">{{ L['含税：'] }}</span> <span class="word131">{{ L['¥'] }}</span>
                                        <span class="txt9">{{ getPartNumber((calcateAmountTaxed - calcateAmountUnTaxed),
                                             'int') }}</span>
                                        <span class="word14">{{ getPartNumber((calcateAmountTaxed - calcateAmountUnTaxed),
                                             'decimal') }}</span>
                                   </div>
                                   <div class="block11">
                                        <span class="word15">{{ L['合计：'] }}</span> <span class="word16">{{ L['¥'] }}</span>
                                        <span class="txt10">{{ getPartNumber(calcateAmountTaxed, 'int') }}</span>
                                        <span class="word17">{{ getPartNumber(calcateAmountTaxed, 'decimal') }}</span>
                                   </div>
                              </div>

                              <div class="block12 flex-row justify-between">
                                   <div class="group31 flex-col"></div>
                                   <span class="word18">{{ L['交易信息'] }}</span>
                              </div>

                              <div class="outer19 flex_row_start_center">
                                   <div class="box1">
                                        <span class="txt8">*</span> <span class="txt9">{{ L['结算方式：'] }}</span>
                                   </div>
                                   <div class="box21 flex-row" @click="checkRadio('bill', 1, L['定金发货'])">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.billType == 1">
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">{{ L['定金发货'] }}</span>
                                   </div>

                                   <div class="box21 flex-row" @click="checkRadio('bill', 2, L['全额付款'])">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.billType == 2">
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">{{ L['全额付款'] }}</span>
                                   </div>

                                   <div class="box21 flex-row" @click="checkRadio('bill', 3, L['分期付款'])">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.billType == 3">
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">{{ L['分期付款'] }}</span>
                                   </div>

                                   <div class="box21 flex_row_start_center">
                                        <div class="flex_row_start_center" @click="checkRadio('bill', 4)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.billType == 4">
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['其他'] }}</span>
                                        </div>

                                        <div style="margin-left:13px">
                                             <div class="box6 flex-col">
                                                  <val-input type="text" v-model="quoDTO.billTypeName" label="billTypeName"
                                                       :disabled="quoDTO.billType != 4" v-show="quoDTO.billType == 4"
                                                       maxlength="50"></val-input>
                                             </div>
                                        </div>

                                   </div>
                              </div>
                              <div class="outer20 flex-row">
                                   <div class="box1">
                                        <span class="txt8">*</span>
                                        <span class="txt9">{{ L['发票要求：'] }}</span>
                                   </div>

                                   <div class="box21 flex_row_start_center" @click="checkRadio('invoice', 1)">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.invoiceType == 1" />
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">{{ L['无需发票'] }}</span>
                                   </div>

                                   <div class="box21 flex_row_start_center" @click="checkRadio('invoice', 2)">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.invoiceType == 2" />
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">{{ L['增值税专用发票'] }}</span>
                                   </div>

                                   <div class="box21 flex_row_start_center" @click="checkRadio('invoice', 3)">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.invoiceType == 3" />
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">{{ L['增值税普通发票'] }}</span>
                                   </div>
                              </div>



                              <div class="block15 flex-row justify-between">
                                   <div class="mod17 flex-col"></div>
                                   <span class="word23">{{ L['联系人信息'] }}</span>
                              </div>
                              <div class="block16 flex-row">
                                   <div class="outer5">
                                        <span class="word24">*</span>
                                        <span class="word25">{{ L['联系人姓名：'] }}</span>
                                   </div>
                                   <div class="container_conName">
                                        <div class="outer6 flex-col">
                                             <val-input type="text" v-model="quoDTO.contactName" label="contactName"
                                                  maxlength="20"></val-input>
                                        </div>
                                   </div>
                                   <div class="outer7">
                                        <span class="info14">*</span>
                                        <span class="info15">{{ L['联系人手机号：'] }}</span>
                                   </div>

                                   <div class="container_conMobile">
                                        <div class="outer8 flex-col">
                                             <val-input type="text" mode="tel" v-model="quoDTO.contactMobile"
                                                  label="contactMobile" maxlength="20"></val-input>
                                        </div>
                                   </div>

                              </div>
                              <div class="block17 flex-row justify-between">
                                   <div class="wrap1 flex-col"></div>
                                   <span class="word26">{{ L['其他说明'] }}</span>
                              </div>
                              <div class="block18 flex-row">
                                   <span class="info8">{{ L['指定配送方式：'] }}</span>
                                   <div class="flex_row_start_center " style="margin-left: 20px;cursor: pointer;"
                                        @click="checkRadio('deliver', 1, L['买方自提'])">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.deliverType == 1" />
                                        <div class="outer10 flex-col" v-else></div>
                                        <span class="txt2">{{ L['买方自提'] }}</span>
                                   </div>

                                   <div class="flex_row_start_center" style="margin-left: 30px;cursor: pointer;"
                                        @click="checkRadio('deliver', 2, L['供方承运'])">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.deliverType == 2" />
                                        <div class="outer10 flex-col" v-else></div>
                                        <span class="txt2">{{ L['供方承运'] }}</span>
                                   </div>

                                   <div class="flex_row_start_center" style="margin-left: 30px;cursor: pointer;">
                                        <div class="flex_row_start_center" @click="checkRadio('deliver', 3)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="quoDTO.deliverType == 3" />
                                             <div class="outer10 flex-col" v-else></div>
                                             <span class="txt2">{{ L['其他'] }}</span>
                                        </div>


                                        <div style="margin-left:14px;">
                                             <div class="outer12 flex-col">
                                                  <val-input type="text" v-model="quoDTO.deliverTypeName"
                                                       label="deliverTypeName" maxlength="20"
                                                       :disabled="quoDTO.deliverType != 3"
                                                       v-show="quoDTO.deliverType == 3"></val-input>
                                             </div>
                                        </div>

                                   </div>
                              </div>
                              <div class="block19 flex-row justify-between">
                                   <span class="info17">{{ L['预计到货：'] }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                        L['达成合约后']
                                   }}</span>
                                   <div>
                                        <div class="mod18 flex-col">
                                             <val-input type="number" v-model="quoDTO.deliverDay" label="deliverDay"
                                                  max="999" mode="int"></val-input>
                                        </div>
                                   </div>

                                   <span class="txt16">{{ L['天'] }}</span>
                              </div>
                              <div class="block20 flex-row justify-between">
                                   <span class="word28">{{ L['其他说明：'] }}</span>
                                   <div class="section6 flex-col">
                                        <div class="group11 flex-row">
                                             <textarea cols="30" rows="7" :placeholder="L['可输入产品的详情情况介绍或其他备注说明']"
                                                  v-model="quoDTO.description" maxlength="500"></textarea>
                                        </div>
                                        <div class="group12 flex-row">
                                             <span class="word30">{{ quoDTO.description && quoDTO.description.length || 0
                                             }}/500</span>
                                        </div>
                                   </div>
                              </div>
                              <div class="block21 flex-col">
                                   <div class="mod19 flex-row justify-between">
                                        <div class="group13 flex-col" @click="dialogVisible = false">
                                             <span class="word31">{{ L['取消'] }}</span>
                                        </div>
                                        <div class="group14 flex-col" @click="initiate_quotation">
                                             <span class="word32">{{ L['确'] }}&nbsp;{{ L['认'] }}</span>
                                        </div>
                                   </div>
                              </div>
                         </div>

                    </div>
               </vali-input-group>
          </el-dialog>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, computed, onMounted } from 'vue'
import { inqUtils } from '@/utils/inquiry_utils.js'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import valInput from './vali-input';
import valiInputGroup from './vali-input-group'

export default {
     components: {
          valInput,
          valiInputGroup
     },
     setup(props) {
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const { unitList, uploadFileSingle, getPartNumber } = inqUtils();

          const feeDTO = reactive({
               fee: '',
               feeName: "",
               taxRate: '',
               taxType: 2
          })
          const dialogVisible = ref(false)

          const totalTaxType = ref(2)

          const route = useRoute()
          const router = useRouter()

          const productDTO = reactive({
               buyNum: 1,
               price: '',
               productImage: "",
               productName: "",
               spec: "",
               taxRate: '',
               taxType: 2,
               unitName: unitList.value[0]
          })

          const quoDTO = reactive({
               billType: 1,
               billTypeName: L['定金发货'],
               contactMobile: "",
               contactName: "",
               deliverDay: 1,
               deliverType: 1,
               deliverTypeName: L['买方自提'],
               description: "",
               feeList: [],
               inquireId: '',
               invoiceType: 1,
               productList: [],
               quoteId: '',
          })

          const validate = reactive({
               productName: {
                    required: true,
                    errMsg: L['请输入产品名称']
               },
               price: {
                    required: true,
                    errMsg: L['请填写正确的产品单价']
               },
               buyNum: {
                    required: true,
                    errMsg: L['请填写产品数量']

               },

               taxRate: {
                    required: true,
                    errMsg: L['请填写税率']
               },

               feeName: {
                    required: true,
                    errMsg: L['请输入收费项']
               },
               fee: {
                    required: true,
                    errMsg: L['请输入正确报价']
               },
               feeTaxRate: {
                    required: true,
                    errMsg: L['请输入正确的税率']
               },

               billTypeName: {
                    required: true,
                    errMsg: L['请填写结算方式']
               },

               contactMobile: {
                    required: true,
                    errMsg: L['请输入手机号'],
                    checkMobile: true,
                    checkMobile_errMsg: L['格式错误，请输入正确的手机号码']
               },
               contactName: {
                    required: true,
                    errMsg: L['请输入联系人姓名']
               },

               deliverDay: {
                    required: true,
                    errMsg: L['请输入正确的合约天数'],
                    notZero: true,
                    notZero_msg: L['合约天数为不为0的正整数']
               },
               deliverTypeName: {
                    required: true,
                    errMsg: L['请填写配送方式']
               },

          })



          const setInquireId = (id) => {
               quoDTO.inquireId = id
               // getInqInfoList(id)
          }

          const changeRateType = (e) => {
               totalTaxType.value = e.target.checked ? 1 : 2
               quoDTO.productList.map(item => {
                    item.taxType = totalTaxType.value
               })

               quoDTO.feeList.map(item => {
                    item.taxType = totalTaxType.value
               })
          }



          const checkRadio = (checkType, value, valueName) => {
               switch (checkType) {
                    case 'deliver': {
                         quoDTO.deliverType = value
                         quoDTO.deliverTypeName = valueName ? valueName : ''
                         break
                    }
                    case 'bill': {
                         quoDTO.billType = value
                         quoDTO.billTypeName = valueName ? valueName : ''
                         break
                    }
                    case 'invoice': {
                         quoDTO.invoiceType = value
                         break
                    }
               }
          }


          const clearImage = (item, index) => {
               item.productImage = ''
               item.productImageUrl = ''
          }

          const initDTO = () => {
               quoDTO.billType = 1
               quoDTO.billTypeName = L['定金发货']
               quoDTO.contactMobile = ""
               quoDTO.contactName = ""
               quoDTO.deliverDay = ""
               quoDTO.deliverType = 1
               quoDTO.deliverTypeName = L['买方自提']
               quoDTO.description = ""
               quoDTO.feeList = []
               quoDTO.inquireId = ''
               quoDTO.invoiceType = 1
               quoDTO.productList = [{ ...JSON.parse(JSON.stringify(productDTO)), key: 'p1' }]
               quoDTO.quoteId = ''
          }


          const changeFile = (e, item, key) => {
               uploadFileSingle(e, (data) => {
                    item.productImageUrl = data.url
                    item.productImage = data.path
                    item.productImagePath = data.path
                    proxy.$refs[key].value = ""
               })
          }

          const addProductPanel = () => {
               quoDTO.productList.push({
                    ...JSON.parse(JSON.stringify(productDTO)),
                    key: `p${quoDTO.productList.length + 1}`,
                    taxType: totalTaxType.value
               })
          }

          const addfeePanel = () => {
               quoDTO.feeList.push({
                    ...JSON.parse(JSON.stringify(feeDTO)),
                    key: `f${quoDTO.feeList.length + 1}`,
                    taxType: totalTaxType.value
               })
          }

          const removeProPanel = (proIdx) => {
               quoDTO.productList.splice(proIdx, 1)
          }

          const removeFeePanel = (feeIdx) => {
               quoDTO.feeList.splice(feeIdx, 1)
          }


          const initiate_quotation = () => {
               proxy.$refs.valiGroup.startVali((value, errGroup) => {
                    if (value) {
                         quotation_post()
                    } else {
                         console.log(errGroup, 'errGroup')
                    }
               })
          }


          const quotation_post = () => {

               let url
               if (quoDTO.quoteId) {
                    url = 'v3/business/front/supplierInquire/updateQuote'
               } else {
                    url = 'v3/business/front/supplierInquire/quote'
               }
               let param = {}
               Object.keys(quoDTO).forEach(item => {
                    if (item == 'productList') {
                         param[item] = quoDTO[item].map(val => {
                              let { buyNum, price, productImagePath, productName, spec, taxRate, unitName } = val
                              return { buyNum, price, productName, spec, taxRate, unitName, productImage: productImagePath || '', taxType: totalTaxType.value }
                         })
                    } else if (item == 'feeList') {
                         param[item] = quoDTO[item].map(val => {
                              let { fee, feeName, taxRate } = val
                              return { fee, feeName, taxRate, taxType: totalTaxType.value }
                         })
                    } else if (quoDTO[item]) {
                         param[item] = JSON.parse(JSON.stringify(quoDTO[item]))
                    } else {
                         delete param[item]
                    }
               })

               proxy.$post(url, param, 'json').then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         setTimeout(() => {

                              if (route.path == '/member/inquiry/myQuo') {
                                   dialogVisible.value = false
                              } else {
                                   router.push('/member/inquiry/myQuo')
                              }

                         }, 500)
                    } else {
                         ElMessage.error(res.msg)
                    }
               })
          }


          const getQuoInfo = (quoteId) => {
               quoDTO.quoteId = quoteId
               proxy.$get('v3/business/front/supplierInquire/quoteDetail', {
                    quoteId
               }).then(res => {
                    if (res.state == 200) {
                         Object.keys(res.data).forEach(item => {
                              if (item == 'productList') {
                                   quoDTO[item] = res.data[item].map((item1, index) => {
                                        let obj = {
                                             productImageUrl: item1.productImage,
                                             ...item1
                                        }
                                        if (item1.taxType == 1) {
                                             obj.price = item1.taxPrice
                                        } else {
                                             obj.price = item1.price
                                        }
                                        delete obj.taxPrice

                                        obj.key = `p${index + 1}`

                                        return obj
                                   })

                                   totalTaxType.value = res.data.productList.every(i => i.taxType == 1) ? 1 : 2

                              } else if (item == 'feeList') {
                                   quoDTO[item] = res.data[item].map((item1, index1) => {
                                        let obj = {
                                             ...item1
                                        }
                                        if (item1.taxType == 1) {
                                             obj.fee = item1.feeTax
                                        } else {
                                             obj.fee = item1.fee
                                        }
                                        delete obj.feeTax

                                        obj.key = `f${index1 + 1}`

                                        return obj
                                   })
                              } else {
                                   quoDTO[item] = res.data[item]

                              }
                         })
                    }
               })
          }

          const getInqInfoList = (id) => {
               proxy.$get('v3/business/front/supplierInquire/detail', {
                    inquireId: id
               }).then(res => {
                    if (res.state == 200) {
                         quoDTO.productList = res.data.productList
                    }
               })
          }

          //计算属性----计算产品列表和其他费用的小计
          const calToTaxed = computed(() => {
               return (buyNum, price, taxRate) => {
                    let p
                    let lTxRt = taxRate || 0
                    if (totalTaxType.value == 1) {
                         p = (Number(buyNum || 0) * Number(price || 0))

                    } else {
                         let untaxedPrice = Number(price || 0) + (Number(price || 0) * (Number(lTxRt) / 100))
                         p = ((buyNum || 0) * untaxedPrice)
                    }
                    return p > 0 ? p.toFixed(2) : 0
               }
          })

          //函数---计算产品列表和其他费用的小计--用于总的含税价计算
          const calToTaxedFunc = (buyNum, price, taxRate) => {
               let p
               let lTxRt = taxRate || 0
               price = price || 0
               buyNum = buyNum || 0
               if (totalTaxType.value == 1) {
                    p = (Number(buyNum) * Number(price))
               } else {
                    let untaxedPrice = Number(price) + (Number(price) * (Number(lTxRt) / 100))
                    p = (buyNum * untaxedPrice)
               }
               return Number(p) > 0 ? Number(p).toFixed(2) : 0
          }

          //函数---计算产品列表和其他费用的总未税价
          const calToUnTaxedFunc = (buyNum, price, taxRate) => {
               let p
               let lTxRt = taxRate || 0
               price = price || 0
               buyNum = buyNum || 1
               if (totalTaxType.value == 1) {
                    let taxedPrice = Number(price) / (1 + (Number(lTxRt) / 100))
                    p = (Number(buyNum) * Number(taxedPrice))

               } else {
                    p = (Number(buyNum) * Number(price))
               }
               return Number(p) > 0 ? Number(p).toFixed(2) : 0
          }


          //计算属性----含税价总和
          const calcateAmountTaxed = computed(() => {
               let totalPro = 0, totalFee = 0
               if (quoDTO.productList.length) {
                    quoDTO.productList.forEach(item => {
                         totalPro += Number(calToTaxedFunc(item.buyNum, item.price, item.taxRate))
                    })
               }


               if (quoDTO.feeList.length) {
                    quoDTO.feeList.forEach(item1 => {
                         totalFee += Number(calToTaxedFunc(1, item1.fee, item1.taxRate))
                    })
               }


               return Number(totalPro) + Number(totalFee)
          })

          //计算属性----未税价总和
          const calcateAmountUnTaxed = computed(() => {
               let totalPro = 0
               if (quoDTO.productList.length) {
                    quoDTO.productList.forEach(item => {
                         totalPro += Number(calToUnTaxedFunc(item.buyNum, item.price, item.taxRate))
                    })
               }
               let totalFee = 0
               if (quoDTO.feeList.length) {
                    quoDTO.feeList.forEach(item1 => {
                         totalFee += Number(calToUnTaxedFunc(1, item1.fee, item1.taxRate))
                    })
               }


               return Number(totalPro) + Number(totalFee)
          })


          const containTaxed = computed(() => {
               return calcateAmountTaxed - calcateAmountUnTaxed
          })

          onMounted(() => {

          })


          const querySearch = (queryString, cb) => {
               const results = queryString ? unitList.value.filter(val => val.indexOf(queryString) === 0) : unitList.value
               cb(results)
          }

          const handleSelect = (item, pro) => {
               pro.unitName = item
          }


          return {
               handleSelect,
               querySearch,
               dialogVisible,
               quoDTO,
               addProductPanel,
               addfeePanel,
               checkRadio,
               removeProPanel,
               removeFeePanel,
               validate,
               changeFile,
               clearImage,
               initiate_quotation,
               unitList,
               setInquireId,
               getQuoInfo,
               initDTO,
               changeRateType,
               calToTaxed,
               calcateAmountTaxed,
               calcateAmountUnTaxed,
               getPartNumber,
               totalTaxType,
               L
          }
     }
};
</script>

<style lang="scss">
.el-dialog .el-dialog__header {
     display: none;
}

.el-dialog__body {
     padding: 0;
}

.my-autocomplete {

     .el-autocomplete-suggestion {
          width: 70px !important;
     }

     li {
          line-height: normal;
          padding: 7px;

          .name {
               text-overflow: ellipsis;
               overflow: hidden;
          }

          .addr {
               font-size: 12px;
               color: #b4b4b4;
          }
     }
}


.block5 {
     .el-input__inner {
          height: 33px !important;
          border: none;
          padding-left: 4px;
          padding-right: 10px;
     }

     .el-input__icon {
          width: 20px;
     }

     .el-input__icon {
          right: 2px;
     }

     .el-input__suffix {
          right: -5px;
     }
}
</style>



<style lang="scss" scoped>
input {
     border: none;
     outline: none;
     height: 36px;
     color: rgba(153, 153, 153, 1);
     font-size: 12px;
}

.up1 {
     width: 60px;
     height: 60px;

     input {
          display: none;
     }

     img {
          width: 60px;
          height: 60px;
     }

     position: relative;

     .el-icon-close {
          position: absolute;
          font-size: 18px;
          top: -10px;
          right: -10px;
          cursor: pointer;
     }
}


.validate {
     .vBorder {
          border-color: #F30606 !important;
     }

     .error_msg {
          display: block;
          margin-top: 2px;
     }
}

.error_msg {
     font-size: 12px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #F30606;
     display: none;
}

.mod13 {
     background-color: rgba(255, 255, 255, 1);
     z-index: 3;
     width: 1218px;
     position: relative;
     height: 800px;
     overflow: auto;

     .outer1 {
          width: 1200px;
          margin-top: 20px;

          .label1 {
               width: 16px;
               height: 16px;
               margin-left: 1164px;
               cursor: pointer;
          }

          .info1 {
               width: 107px;
               height: 26px;

               color: rgba(51, 51, 51, 1);
               font-size: 26px;
               ;
               text-align: left;
               white-space: nowrap;
               line-height: 26px;
               display: block;
               margin: 1px 0 0 547px;
          }

          .block1 {
               width: 74px;
               height: 18px;
               margin: 31px 0 0 20px;

               .bd1 {
                    background-color: rgba(40, 95, 222, 1);
                    width: 6px;
                    height: 18px;
               }

               .word1 {

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    margin-top: 1px;
                    display: block;
                    margin-left: 10px;
               }
          }

          .word2 {
               width: 58px;
               height: 14px;

               color: rgba(51, 51, 51, 1);
               font-size: 14px;

               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 21px 0 0 20px;
          }

          .block2 {
               background-color: rgba(247, 248, 250, 1);
               width: 1160px;
               height: 44px;
               margin: 10px 0 0 20px;
          }

          .block4 {
               height: 82px;
               border: 1px solid rgba(220, 220, 220, 1);
               width: 1160px;
               margin: -1px 0 0 20px;


               .outer2 {
                    width: 1101px;
                    height: 60px;



                    .box1 {
                         background-color: rgba(244, 244, 244, 1);
                         height: 60px;
                         border: 1px dashed rgba(210, 210, 210, 1);
                         width: 60px;
                         position: relative;

                         .outer3 {
                              background-color: rgba(220, 220, 220, 1);
                              border-radius: 1px;
                              width: 28px;
                              height: 2px;
                              margin: 29px 0 0 16px;
                         }

                         .outer4 {
                              background-color: rgba(220, 220, 220, 1);
                              border-radius: 1px;
                              z-index: 52;
                              position: absolute;
                              left: 29px;
                              top: 16px;
                              width: 2px;
                              height: 28px;
                         }
                    }


                    .container_quo1 {
                         margin: 12px 0 0 43px;

                    }

                    .box23 {
                         background-color: rgba(255, 255, 255, 1);
                         height: 36px;
                         width: 170px;

                         input {
                              height: 36px;
                              border: none;
                              outline: none;
                              background: transparent
                         }
                    }

                    .box3 {
                         background-color: rgba(255, 255, 255, 1);
                         height: 36px;
                         width: 150px;
                         margin: 12px 0 0 33px;

                         input {
                              height: 36px;
                              border: none;
                              outline: none;
                              background: transparent
                         }
                    }


                    .container_num {
                         margin: 12px 0 0 42px;

                         .box4 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 36px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 129px;
                              padding-right: 5px;
                              position: relative;
                              border-radius: 5px;

                              .vali_input_buyNum {
                                   width: 63px !important;
                                   height: 34px;
                                   border: none;
                                   outline: none;
                                   background: transparent;
                                   border-radius: 5px 0 0 5px !important;
                              }

                              .vali_input_unitName {
                                   width: 48px !important;
                                   height: 34px;
                                   border: none;
                                   outline: none;
                                   background: transparent
                              }

                              .block5 {
                                   width: 60px;
                                   height: 33px;
                                   border-left: 1px solid #c9c9c9;



                                   .word4 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                   }

                                   .icon3 {
                                        width: 13px;
                                        height: 7px;
                                   }
                              }

                              .layer18 {
                                   position: absolute;
                                   width: 50px;
                                   top: -1px;
                                   right: -51px;

                                   border: 1px solid #c9c9c9;

                                   .validate_input {
                                        height: 34px;
                                        padding: 0 2px;
                                   }
                              }
                         }

                    }


                    .container_price {
                         margin: 12px 0 0 73px;

                         .box5 {
                              background-color: rgba(255, 255, 255, 1);
                              width: 99px;
                              height: 36px;


                              input {
                                   height: 36px;
                                   border: none;
                                   outline: none;
                                   background: transparent
                              }
                         }

                    }

                    .container_tax {
                         margin: 12px 0 0 52px;

                         .box6 {
                              background-color: rgba(255, 255, 255, 1);
                              width: 88px;
                              height: 36px;

                              input {
                                   height: 36px;
                                   border: none;
                                   outline: none;
                                   background: transparent
                              }
                         }
                    }




                    .info5 {
                         width: 11px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 25px 0 0 5px;
                    }

                    .word5 {
                         width: 37px;
                         height: 11px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 24px 0 0 56px;
                    }

                    .box7 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 50%;
                         height: 18px;
                         width: 18px;
                         margin: 21px 0 0 34px;

                         .main1 {
                              background-color: rgba(255, 255, 255, 1);
                              width: 12px;
                              height: 2px;
                              margin: 8px 0 0 3px;
                         }
                    }
               }
          }

          .block6 {
               border-radius: 4px;
               height: 35px;
               border: 1px dashed rgba(40, 95, 222, 1);
               width: 150px;
               margin: 25px 0 0 525px;
               cursor: pointer;

               .txt4 {
                    color: rgba(40, 95, 222, 1);
                    font-size: 14px;
                    margin: 11px 0 0 40px;
               }
          }

          .info6 {
               width: 57px;
               height: 14px;

               color: rgba(51, 51, 51, 1);
               font-size: 14px;

               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 33px 0 0 22px;
          }

          .block7 {
               width: 1160px;
               margin: 15px 0 0 20px;

               .bd3 {
                    height: 45px;
                    border: 1px solid rgba(220, 220, 220, 1);
                    width: 1160px;
                    background-color: rgba(247, 248, 250, 1);

                    .group2 {
                         width: 912px;
                         height: 16px;
                         margin: 15px 0 0 119px;

                         .mod10 {
                              width: 54px;
                              height: 14px;

                              font-size: 0;

                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 1px;

                              .info7 {
                                   width: 54px;
                                   height: 14px;

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;

                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt55 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .mod11 {
                              width: 103px;
                              height: 15px;

                              font-size: 0;

                              text-align: left;
                              white-space: nowrap;
                              line-height: 15px;
                              margin: 1px 0 0 206px;

                              .info8 {
                                   width: 103px;
                                   height: 15px;

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;

                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .word66 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .word77 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 12px;

                                   white-space: nowrap;
                                   line-height: 12px;
                              }
                         }

                         .mod12 {
                              width: 39px;
                              height: 13px;

                              font-size: 0;

                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin: 1px 0 0 225px;

                              .word8 {
                                   width: 39px;
                                   height: 13px;

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;

                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt6 {
                                   width: 39px;
                                   height: 13px;

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .word9 {
                              width: 53px;
                              height: 15px;

                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;

                              text-align: left;
                              white-space: nowrap;
                              line-height: 15px;
                              margin-left: 232px;
                              display: block;
                         }
                    }
               }
          }


          .layer26 {
               height: 75px;
               border: 1px solid rgba(220, 220, 220, 1);
               border-top: 0;

               .box6 {
                    width: 1078px;
                    height: 36px;

                    .container_feeName {
                         margin-left: 20px;

                         .bd9 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 36px;
                              width: 170px;

                              input {
                                   border: none;
                                   outline: none;
                                   height: 36px;
                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;
                              }
                         }
                    }


                    .container_fee {
                         margin-left: 113px;

                         .bd10 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 36px;
                              width: 170px;

                              input {
                                   border: none;
                                   outline: none;
                                   height: 36px;
                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;
                              }
                         }
                    }

                    .container_feeTaxRate {
                         margin-left: 174px;

                         .bd11 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 36px;
                              width: 88px;

                              input {
                                   border: none;
                                   outline: none;
                                   height: 36px;
                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;
                              }
                         }
                    }


                    .info24 {
                         width: 11px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 13px 0 0 5px;
                    }

                    .info25 {
                         width: 40px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 13px 0 0 194px;
                    }

                    .bd12 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 50%;
                         height: 18px;
                         width: 18px;
                         margin: 9px 0 0 75px;

                         .bd13 {
                              background-color: rgba(255, 255, 255, 1);
                              width: 12px;
                              height: 2px;
                              margin: 8px 0 0 3px;
                         }
                    }
               }
          }

          .block8 {
               width: 641px;
               height: 35px;
               margin: 30px 0 0 525px;

               .section4 {
                    border-radius: 4px;
                    height: 35px;
                    border: 1px dashed rgba(40, 95, 222, 1);
                    width: 150px;
                    cursor: pointer;

                    .word10 {
                         width: 85px;
                         height: 14px;

                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 11px 0 0 33px;
                    }
               }

               .section5 {
                    background-color: rgba(255, 255, 255, 1);
                    width: 12px;
                    height: 2px;
                    margin-top: 2px;
               }
          }

          .middle1 {
               padding-right: 22px;
          }

          .block9 {
               font-size: 14px;
               white-space: nowrap;
               line-height: 14px;
               margin: 15px 0 0 0px;

               .info9 {
                    width: 122px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }

               .word11 {
                    width: 122px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }

               .word12 {
                    width: 122px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 16px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 16px;
               }

               .txt71 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    line-height: 14px;
               }
          }

          .block10 {

               font-size: 14px;
               line-height: 14px;
               margin: 15px 0 0 0px;

               .txt8 {
                    width: 98px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }

               .word131 {
                    width: 98px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }

               .txt9 {
                    width: 98px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 16px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 16px;
               }

               .word14 {
                    width: 98px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }
          }

          .block11 {
               font-size: 14px;

               text-align: left;
               line-height: 14px;
               margin: 15px 0 0 0;

               .word15 {
                    width: 107px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }

               .word16 {
                    width: 107px;
                    height: 13px;

                    color: rgba(243, 6, 6, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }

               .txt10 {
                    width: 107px;

                    color: rgba(243, 6, 6, 1);
                    font-size: 16px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 16px;
               }

               .word17 {
                    width: 107px;
                    height: 13px;

                    color: rgba(243, 6, 6, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
               }
          }

          .block12 {
               width: 81px;
               height: 18px;
               margin: 22px 0 0 20px;

               .group31 {
                    background-color: rgba(40, 95, 222, 1);
                    width: 6px;
                    height: 18px;
               }

               .word18 {
                    color: rgba(18, 18, 18, 1);
                    font-size: 16px;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 16px;
                    margin-left: 10px;
               }
          }



          .outer19 {

               margin: 17px 0 0 35px;

               .box1 {
                    width: 74px;
                    height: 14px;
                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;

                    .txt8 {
                         width: 74px;
                         height: 14px;
                         color: rgba(240, 2, 2, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .txt9 {
                         width: 74px;
                         height: 14px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .box21 {
                    margin-left: 23px;

                    img {
                         width: 18px;
                         height: 18px;
                    }

                    .box3 {
                         background-color: rgba(255, 255, 255, 1);
                         border-radius: 50%;
                         width: 18px;
                         height: 18px;
                         border: 2px solid rgba(201, 201, 201, 1);
                    }

                    .info11 {

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         margin: 0px 0 0 8px;
                    }
               }

               .word17 {
                    width: 57px;
                    height: 14px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 7px 0 0 9px;
               }

               .box4 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(201, 201, 201, 1);
                    margin: 5px 0 0 39px;

               }

               .word18 {
                    width: 57px;
                    height: 14px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 7px 0 0 9px;
               }

               .box5 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(201, 201, 201, 1);
                    margin: 5px 0 0 43px;
               }

               .info12 {
                    width: 28px;
                    height: 13px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 7px 0 0 9px;
               }

               .box6 {
                    width: 110px;
                    height: 28px;
               }
          }

          .outer20 {
               width: 508px;
               height: 18px;
               margin: 16px 0 0 35px;

               .box21 {
                    margin-left: 23px;

                    img {
                         width: 18px;
                         height: 18px;
                    }

                    .box3 {
                         background-color: rgba(255, 255, 255, 1);
                         border-radius: 50%;
                         width: 18px;
                         height: 18px;
                         border: 2px solid rgba(201, 201, 201, 1);
                    }

                    .info11 {

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         margin: 0px 0 0 8px;
                    }
               }

               .box1 {
                    width: 74px;
                    height: 14px;
                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;

                    .txt8 {
                         width: 74px;
                         height: 14px;
                         color: rgba(240, 2, 2, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .txt9 {
                         width: 74px;
                         height: 14px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

          }

          .block15 {
               width: 98px;
               height: 18px;
               margin: 25px 0 0 20px;

               .mod17 {
                    background-color: rgba(40, 95, 222, 1);
                    width: 6px;
                    height: 18px;
               }

               .word23 {

                    color: rgba(18, 18, 18, 1);
                    font-size: 16px;
                    line-height: 16px;
                    margin-left: 10px;
               }
          }

          .block16 {
               width: 930px;
               height: 36px;
               margin: 16px 0 0 35px;

               .outer5 {
                    width: 89px;
                    height: 14px;

                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 11px;

                    .word24 {
                         width: 89px;
                         height: 14px;

                         color: rgba(240, 2, 2, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .word25 {
                         width: 89px;
                         height: 14px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .container_conName {
                    margin-left: 16px;

                    .outer6 {
                         background-color: rgba(255, 255, 255, 1);
                         width: 348px;
                         height: 36px;

                    }
               }



               .outer7 {
                    width: 103px;
                    height: 14px;

                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin: 11px 0 0 37px;

                    .info14 {
                         width: 103px;
                         height: 14px;

                         color: rgba(240, 2, 2, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .info15 {
                         width: 103px;
                         height: 14px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .container_conMobile {
                    margin-left: 17px;

                    .outer8 {
                         background-color: rgba(255, 255, 255, 1);
                         width: 320px;
                         height: 36px;
                    }
               }


          }

          .block17 {
               width: 81px;
               height: 18px;
               margin: 24px 0 0 20px;

               .wrap1 {
                    background-color: rgba(40, 95, 222, 1);
                    width: 6px;
                    height: 18px;
               }

               .word26 {
                    color: rgba(18, 18, 18, 1);
                    font-size: 16px;
                    white-space: nowrap;
                    line-height: 16px;
                    margin-left: 10px;
               }
          }

          .block18 {
               margin: 13px 0 0 35px;

               .info8 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 7px;
                    display: block;
               }

               .outer9 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    height: 18px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 18px;
                    margin: 5px 0 0 15px;

                    .group1 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 50%;
                         width: 10px;
                         height: 10px;
                         margin: 4px 0 0 4px;
                    }
               }

               .txt2 {

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    display: block;
                    margin: 0px 0 0 8px;
               }

               .outer10 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(201, 201, 201, 1);
               }

               .word11 {
                    width: 58px;
                    height: 14px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 7px 0 0 9px;
               }

               .outer11 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(201, 201, 201, 1);
                    margin: 5px 0 0 35px;
               }

               .word12 {
                    width: 28px;
                    height: 13px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 7px 0 0 9px;
               }

               .outer12 {
                    width: 120px;
                    height: 28px;
               }
          }

          .block19 {
               width: 324px;
               height: 36px;
               margin: 14px 0 0 35px;

               .info17 {
                    width: 170px;
                    height: 14px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 10px;
                    display: block;
               }

               .mod18 {
                    background-color: rgba(255, 255, 255, 1);
                    width: 124px;
                    height: 36px;
                    margin: 0 4px;
               }

               .txt16 {
                    width: 14px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 11px;
                    display: block;
               }
          }

          .block20 {
               width: 1128px;
               height: 164px;
               margin: 25px 0 0 35px;

               .word28 {
                    width: 63px;
                    height: 14px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 7px;
                    display: block;
               }

               .section6 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 164px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    width: 1046px;
                    margin-left: 10px;

                    .group11 {

                         margin: 10px;

                         textarea {
                              width: 100%;
                              resize: none;
                              color: rgba(153, 153, 153, 1);
                              border: none;
                              outline: none;
                         }

                         .word29 {
                              width: 249px;
                              height: 13px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Normal;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 13px;
                              display: block;
                         }
                    }

                    .group12 {
                         width: 34px;
                         height: 13px;
                         align-self: flex-end;
                         margin-top: 10px;
                         margin-right: 10px;

                         .word30 {
                              width: 34px;
                              height: 13px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;

                              text-align: left;
                              white-space: nowrap;
                              line-height: 13px;
                              display: block;
                         }
                    }
               }
          }

          .block21 {
               box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
               background-color: rgba(255, 255, 255, 1);
               height: 79px;
               margin-top: 35px;
               width: 1200px;

               .mod19 {
                    width: 261px;
                    height: 36px;
                    margin: 23px 0 0 470px;

                    .group13 {
                         border-radius: 4px;
                         height: 36px;
                         border: 1px solid rgba(201, 201, 201, 1);
                         width: 102px;

                         .word31 {
                              width: 33px;
                              cursor: pointer;
                              color: rgba(102, 98, 98, 1);
                              font-size: 16px;
                              font-family: MicrosoftYaHei;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 16px;
                              display: block;
                              margin: 11px 0 0 34px;
                         }
                    }

                    .group14 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 4px;
                         height: 36px;
                         width: 102px;
                         margin-left: 57px;
                         cursor: pointer;

                         .word32 {
                              width: 39px;
                              height: 15px;

                              color: rgba(255, 255, 255, 1);
                              font-size: 16px;
                              font-family: MicrosoftYaHei;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 16px;
                              display: block;
                              margin: 10px 0 0 31px;
                         }
                    }
               }
          }
     }

     .outer9 {
          z-index: 8;
          height: 46px;
          border: 1px solid rgba(220, 220, 220, 1);
          width: 1160px;
          border-bottom: none;

          .outer10 {
               width: 1042px;
               height: 17px;
               margin: 14px 0 0 49px;

               .word33 {
                    width: 57px;
                    height: 14px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 2px;
                    display: block;
               }

               .bd4 {
                    width: 68px;
                    height: 14px;

                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin: 2px 0 0 93px;

                    .word34 {
                         width: 68px;
                         height: 14px;

                         color: rgba(230, 0, 18, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .word35 {
                         width: 68px;
                         height: 14px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .word36 {
                    width: 63px;
                    height: 15px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 15px;
                    display: block;
                    margin: 2px 0 0 115px;
               }

               .bd5 {

                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 14px;
                    margin: 2px 0 0 124px;

                    .word37 {
                         width: 39px;
                         height: 14px;

                         color: rgba(230, 0, 18, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .word38 {
                         width: 39px;
                         height: 14px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .bd6 {
                    width: 64px;
                    height: 15px;

                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 15px;
                    margin: 2px 0 0 109px;

                    .txt17 {
                         width: 64px;
                         height: 15px;

                         color: rgba(230, 0, 18, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .word39 {
                         width: 64px;
                         height: 15px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .bd7 {
                    height: 14px;
                    width: 14px;
                    margin: 2px 0 0 7px;

                    .label3 {
                         height: 14px;
                         width: 14px;
                         margin: 3px 0 0 2px;
                    }
               }

               .word40 {
                    width: 29px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 2px 0 0 2px;
               }

               .bd8 {
                    width: 39px;
                    height: 13px;

                    font-size: 0;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-left: 71px;

                    .txt18 {
                         width: 39px;
                         height: 13px;

                         color: rgba(230, 0, 18, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }

                    .info18 {
                         width: 39px;
                         height: 13px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                    }
               }

               .word41 {
                    width: 53px;
                    height: 15px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    text-align: left;
                    white-space: nowrap;
                    line-height: 15px;
                    display: block;
                    margin: 1px 0 0 77px;
               }
          }
     }
}
</style>